import classNames from "classnames"
import { createElement } from "react"

import { Color } from "../../../constants/V2/color"
import { getTextColorClass } from "../../../utils/V2/color"

export type ResponsiveTypographySize =
  | "display1"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "lead-lg"
  | "lead-md"
  | "lead-sm"
  | "body-lg"
  | "body-md"
  | "body-sm"
  | "subscript-lg"
  | "subscript-md"
  | "subscript-sm"

export type FixedTypographySize =
  | "display1-fixed"
  | "h1-fixed"
  | "h2-fixed"
  | "h3-fixed"
  | "h4-fixed"
  | "h5-fixed"
  | "lead-lg-fixed"
  | "lead-md-fixed"
  | "lead-sm-fixed"
  | "body-lg-fixed"
  | "body-md-fixed"
  | "body-sm-fixed"
  | "subscript-lg-fixed"
  | "subscript-md-fixed"
  | "subscript-sm-fixed"

export type TypographySize = ResponsiveTypographySize | FixedTypographySize

type Element = "p" | "h1" | "h2" | "h3" | "h4" | "h5"

type Props = {
  text: string
  size: TypographySize
  font: "grotesk" | "grotesk-25" | "grotesk-10"
  weight: "book" | "medium"
  color: Color
  element?: Element
  className?: string
  onClick?: () => any
  leadingNone?: boolean
}

const Typography = ({
  text,
  size,
  font,
  weight,
  color,
  element = "p",
  className,
  onClick,
  leadingNone = false,
  ...props
}: Props) => {
  /*
    Note: Since this component is the only way in which text should be added to the site,
    both font size and family can be resolved locally here instead of being exported globally.
  */
  const getFontSizeClass = () => {
    switch (size) {
      case "display1":
        return "text-responsive-display1-v2"
      case "display1-fixed":
        return "text-display1-v2"
      case "h1":
        return "text-responsive-h1-v2"
      case "h1-fixed":
        return "text-h1-v2"
      case "h2":
        return "text-responsive-h2-v2"
      case "h2-fixed":
        return "text-h2-v2"
      case "h3":
        return "text-responsive-h3-v2"
      case "h3-fixed":
        return "text-h3-v2"
      case "h4":
        return "text-responsive-h4-v2"
      case "h4-fixed":
        return "text-h4-v2"
      case "h5":
        return "text-responsive-h5-v2"
      case "h5-fixed":
        return "text-h5-v2"
      case "lead-lg":
        return "text-responsive-lead-lg-v2"
      case "lead-lg-fixed":
        return "text-lead-lg-v2"
      case "lead-md":
        return "text-responsive-lead-md-v2"
      case "lead-md-fixed":
        return "text-lead-md-v2"
      case "lead-sm":
        return "text-responsive-lead-sm-v2"
      case "lead-sm-fixed":
        return "text-lead-sm-v2"
      case "body-lg":
        return "text-responsive-body-lg-v2"
      case "body-lg-fixed":
        return "text-body-lg-v2"
      case "body-md":
        return "text-responsive-body-md-v2"
      case "body-md-fixed":
        return "text-body-md-v2"
      case "body-sm":
        return "text-responsive-body-sm-v2"
      case "body-sm-fixed":
        return "text-body-sm-v2"
      case "subscript-lg":
        return "text-responsive-subscript-lg-v2"
      case "subscript-lg-fixed":
        return "text-subscript-lg-v2"
      case "subscript-md":
        return "text-responsive-subscript-md-v2"
      case "subscript-md-fixed":
        return "text-subscript-md-v2"
      case "subscript-sm":
        return "text-responsive-subscript-sm-v2"
      case "subscript-sm-fixed":
        return "text-subscript-sm-v2"
      default:
        return ""
    }
  }

  const getFontFamilyClass = () => {
    switch (font) {
      case "grotesk":
        return "font-grotesk"
      case "grotesk-25":
        return "font-grotesk-25"
      case "grotesk-10":
        return "font-grotesk-10"
      default:
        return ""
    }
  }

  return createElement(
    element,
    {
      className: classNames(
        getFontSizeClass(),
        getFontFamilyClass(),
        getTextColorClass(color),
        weight === "book" ? "font-normal" : "font-medium",
        { "!leading-none": leadingNone },
        className
      ),
      onClick,
      ...props,
    },
    text
  )
}

export default Typography
