import classNames from "classnames"
import React, { useRef, useState, useEffect } from "react"

import Logo from "./Logo"

const Splash = () => {
  const [animationDone, setAnimationDone] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const splashWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleAnimationEnd = () => setAnimationDone(true)

    if (splashWrapperRef.current) {
      splashWrapperRef.current.addEventListener(
        "animationend",
        handleAnimationEnd
      )
    }

    setLoaded(true)

    return () => {
      if (splashWrapperRef.current) {
        splashWrapperRef.current.removeEventListener(
          "animationend",
          handleAnimationEnd
        )
      }
    }
  }, [])

  return !animationDone ? (
    <div
      ref={splashWrapperRef}
      className={classNames("splash-wrapper", { animated: loaded })}
    >
      <div className="relative w-full h-full flex items-center justify-center">
        <div className={classNames("splash", { animated: loaded })}></div>
        <Logo
          className={classNames("absolute splash-image fill-white-v2", {
            animated: loaded,
          })}
        />
      </div>
    </div>
  ) : null
}

export default Splash
