import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { getGatsbyImage } from "../../../../plugins/storyblok-image-plugin/src"
import { getImageDimensionsFromUrl } from "../../../utils/image"

type Props = {
  image: string
  alt: string
  width?: number
  height?: number
  loadType?: "eager" | "lazy"
  quality?: number
  rounded?: boolean
  /**
   * "auto" requires at least a width or height
   */
  aspectRatio?: "1:1" | "4:3" | "3:4" | "16:9" | "fullWidth" | "auto"
  className?: string
  imageObjectFit?: "contain" | "cover"
}

const GatsbyStoryblokImage = ({
  image,
  alt = "",
  width,
  height,
  loadType = "lazy",
  quality = undefined,
  rounded = false,
  aspectRatio = "4:3",
  className,
  imageObjectFit = "cover",
}: Props) => {
  if (image.includes(".svg")) {
    return (
      <img
        loading={"lazy"}
        className={classNames(
          { "translate-x-0 overflow-hidden rounded": rounded },
          "h-auto max-w-full",
          className
        )}
        src={image}
        alt={alt}
        width={width}
      />
    )
  } else if (width && height) {
    const imageProps = getGatsbyImage(image, {
      width: width,
      height: height,
      layout: "fixed",
      quality,
    })
    return (
      <GatsbyImage
        image={imageProps}
        alt={alt}
        loading={loadType}
        className={classNames({ "translate-x-0 rounded": rounded }, className)}
        imgStyle={{ objectFit: imageObjectFit }}
      />
    )
  } else {
    let ASPECT_RATIO: number | undefined,
      WIDTH: number | undefined,
      HEIGHT: number | undefined

    if (aspectRatio === "fullWidth") {
      ASPECT_RATIO = undefined
      WIDTH = width || parseInt(getImageDimensionsFromUrl(image).width)
    } else if (aspectRatio === "auto") {
      const imageDimensions = getImageDimensionsFromUrl(image)
      const imageDimensionsHeight = parseInt(imageDimensions.height)
      const imageDimensionsWidth = parseInt(imageDimensions.width)

      if (width) {
        ASPECT_RATIO = imageDimensionsHeight / imageDimensionsWidth
        WIDTH = width
        HEIGHT = Math.floor(ASPECT_RATIO && WIDTH * ASPECT_RATIO)
      } else if (height) {
        ASPECT_RATIO = imageDimensionsWidth / imageDimensionsHeight
        HEIGHT = height
        WIDTH = Math.floor(ASPECT_RATIO && HEIGHT * ASPECT_RATIO)
      }
    } else {
      if (height) {
        ASPECT_RATIO =
          parseInt(aspectRatio.split(":")[0]) /
          parseInt(aspectRatio.split(":")[1])
      } else {
        ASPECT_RATIO =
          parseInt(aspectRatio.split(":")[1]) /
          parseInt(aspectRatio.split(":")[0])
      }

      if (width) {
        WIDTH = width
        HEIGHT = ASPECT_RATIO && WIDTH * ASPECT_RATIO
      } else if (height) {
        HEIGHT = height
        WIDTH = ASPECT_RATIO && HEIGHT * ASPECT_RATIO
      } else {
        const imageDimensions = getImageDimensionsFromUrl(image)
        WIDTH = parseInt(imageDimensions.width)
        HEIGHT = parseInt(imageDimensions.height)
      }
    }

    const imageProps = getGatsbyImage(image, {
      width: WIDTH,
      height: HEIGHT,
      layout: aspectRatio === "fullWidth" ? "fullWidth" : "constrained",
      quality,
      aspectRatio: ASPECT_RATIO,
    })

    return (
      <GatsbyImage
        image={imageProps}
        alt={alt}
        loading={loadType}
        className={classNames(
          { "translate-x-0 overflow-hidden rounded": rounded },
          "max-w-full",
          className
        )}
        imgStyle={{ objectFit: imageObjectFit }}
      />
    )
  }
}

export default GatsbyStoryblokImage
