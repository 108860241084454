import { LocationProvider } from "@reach/router"
import React from "react"
import { ThemeProvider } from "styled-components"

import CartProvider from "./cart/store"
import NavigationV2Provider from "./components/V2/Navigation/context"
import { NotificationProvider } from "./notifications/context"
import theme from "./theme"

interface RootElementProps {
  element: React.ReactElement[] | React.ReactElement
}

const WrapRootElement = ({ element }: RootElementProps) => {
  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <NavigationV2Provider>
            <CartProvider>{element}</CartProvider>
          </NavigationV2Provider>
        </NotificationProvider>
      </ThemeProvider>
    </LocationProvider>
  )
}

export default WrapRootElement
