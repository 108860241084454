// MARK: used in v2
import { Platform } from "@yoco/shop-sdk/lib/types"
import { useCallback, useContext, useEffect, useState } from "react"

import { REASON_NOT_AVAILABLE_MAPPING, DEFAULT_STOREFRONT } from "./constants"
import { CartContext } from "./store"
import { NotificationContext } from "../notifications/context"
import { getCookie, isMobileDevice } from "../utils/analytics"
import {
  consolidateProduct,
  fetchCartId,
  fetchCartProducts,
  shop,
  storeCartId,
  storeCartProducts,
  StoredProduct,
} from "../utils/shop"

import { useGetProductBySku } from "@hooks/products"
import { useGetStoryblokProduct } from "@hooks/V2/storyblok/useProduct"
import { Product } from "components/elements/V2/Buttons/AddToCartButton"

export const useFetchConsolidatedCartProducts =
  (): (() => YocoCom.ConsolidatedProduct[]) => {
    const products = fetchCartProducts()
    const getStoryblokProduct = useGetStoryblokProduct()
    const getProductBySku = useGetProductBySku()

    const fetchConsolidatedCartProducts = useCallback(() => {
      const consolidatedProducts: YocoCom.ConsolidatedProduct[] = []

      if (products) {
        products.map((product) => {
          const storyblokProduct = getStoryblokProduct(product.id)
          const sdkProduct = getProductBySku(product.sku)

          if (!storyblokProduct || !sdkProduct) return null
          return consolidatedProducts.push(
            consolidateProduct(
              product.id,
              product.quantity,
              sdkProduct,
              storyblokProduct,
              product.bundleId,
              product.bundleName,
              product.isBundleParent
            )
          )
        })
      }

      return consolidatedProducts
    }, [products, getStoryblokProduct, getProductBySku])

    return fetchConsolidatedCartProducts
  }

export const useRemoveProduct = (): ((
  sku: string,
  bundleId?: string
) => Promise<void>) => {
  const cartContext = useContext(CartContext)
  const getProduct = useGetProductBySku()
  const cartId = fetchCartId()

  const removeProduct = useCallback(
    async (sku: string, bundleId?: string) => {
      const product = getProduct(sku)

      if (!cartId || !product)
        return console.error(`Missing cartId or product sku(${sku})`)

      const products = fetchCartProducts() ?? []

      storeCartProducts(
        products.filter((product) => {
          if (bundleId) {
            return product.bundleId != bundleId
          }

          if (product.sku === sku) {
            // Return all matching products that are in bundles
            return product.bundleId != undefined
          }

          return product.sku !== sku
        })
      )

      cartContext.setProducts(
        cartContext.products.filter((product) => {
          if (bundleId) {
            return product.bundleId != bundleId
          }

          if (product.sku === sku) {
            // Return all matching products that are in bundles
            return product.bundleId != undefined
          }
          return product.sku !== sku
        })
      )
    },
    [cartId, getProduct, cartContext]
  )

  return removeProduct
}

export const useInitCart = (): (() => Promise<string | null>) => {
  const cartContext = useContext(CartContext)
  const notificationContext = useContext(NotificationContext)

  const initCard = async () => {
    let cartId = fetchCartId()

    if (!cartId) {
      try {
        const whoAreYou = getCookie("wau")
        const cart = await shop.carts.create({
          source: "shop",
          tracking_attribution: {
            platform: isMobileDevice() ? Platform.MOBILE_WEB : Platform.WEB,
            wau: whoAreYou || "",
          },
        })
        cartId = cart.id
        cartContext.setCartId(cart.id)
        storeCartId(cartId)
      } catch (error: any) {
        notificationContext.setNotification({
          type: "danger",
          title: "Request failed",
          message: error?.message,
        })
      }
    }

    return cartId
  }

  return initCard
}

export const useAddToCart = (): ((
  id: string,
  sku: string,
  quantity: number
) => Promise<void>) => {
  const initCart = useInitCart()
  const cartContext = useContext(CartContext)
  const getProduct = useGetProductBySku()
  const getStoryblokProduct = useGetStoryblokProduct()

  const addToCart = useCallback(
    async (id: string, sku: string, quantity: number) => {
      const sdkProduct = getProduct(sku)
      const storyblokProduct = getStoryblokProduct(id)
      const cart = await initCart()

      if (!cart) return console.error("Cart failed to initialise")
      if (!sdkProduct || !storyblokProduct) {
        return console.error(`Failed to add product. Invalid sku: ${sku}`)
      }

      const products = fetchCartProducts() ?? []
      const consolidatedProduct = consolidateProduct(
        id,
        quantity,
        sdkProduct,
        storyblokProduct
      )

      const storedProducts = [...products, { id, sku, quantity }]

      cartContext.setProducts([...cartContext.products, consolidatedProduct])

      storeCartProducts(storedProducts)
    },
    [cartContext, getProduct, getStoryblokProduct, initCart]
  )

  return addToCart
}

export const useAddMultipleToCart = (): ((
  bundleId: string,
  bundleName: string,
  products: Product[],
  quantity: number
) => Promise<void>) => {
  const initCart = useInitCart()
  const cartContext = useContext(CartContext)
  const getProduct = useGetProductBySku()
  const getStoryblokProduct = useGetStoryblokProduct()

  const addMultipleToCart = useCallback(
    async (
      bundleId: string,
      bundleName: string,
      products: Product[],
      quantity: number
    ) => {
      const cart = await initCart()
      const updatedProducts = cartContext.products
      const consolidatedProducts: YocoCom.ConsolidatedProduct[] = []
      const existing_products = fetchCartProducts() ?? []

      const toStoreProducts: StoredProduct[] = []
      if (!cart) return console.error("Cart failed to initialise")

      products.forEach((product, index) => {
        const sdkProduct = getProduct(product.sku)
        const storyblokProduct = getStoryblokProduct(product.id)
        if (!sdkProduct || !storyblokProduct) {
          return console.error(
            `Failed to add product. Invalid sku: ${product.sku}`
          )
        }

        const isBundleParent = index == 0
        const consolidatedProduct = consolidateProduct(
          product.id,
          quantity,
          sdkProduct,
          storyblokProduct,
          bundleId,
          bundleName,
          isBundleParent //bundleParent
        )

        toStoreProducts.push({
          id: product.id,
          sku: product.sku,
          quantity,
          bundleId,
          bundleName,
          isBundleParent,
        })
        consolidatedProducts.push(consolidatedProduct)
      })

      const storedProducts = [...existing_products, ...toStoreProducts]
      cartContext.setProducts([...updatedProducts, ...consolidatedProducts])

      storeCartProducts(storedProducts)
    },
    [cartContext, getProduct, getStoryblokProduct, initCart]
  )

  return addMultipleToCart
}

export const useGetCartQuantity = (): number => {
  const cartContext = useContext(CartContext)

  return (cartContext.products as YocoCom.ConsolidatedProduct[]).reduce(
    (accumulatedProducts: number, product: YocoCom.ConsolidatedProduct) => {
      return accumulatedProducts + product.quantity
    },
    0
  )
}

export const useUpdateCartQuantity = () => {
  const cartContext = useContext(CartContext)
  const updateQuantity = useCallback(
    (productSku: string, quantity: number, bundleId?: string) => {
      const updatedProducts: YocoCom.ConsolidatedProduct[] =
        cartContext.products.map(
          (contextProduct: YocoCom.ConsolidatedProduct) => {
            if (bundleId) {
              if (contextProduct.bundleId === bundleId)
                return { ...contextProduct, quantity: quantity }
            } else {
              if (
                contextProduct.sku === productSku &&
                contextProduct.bundleId === undefined
              )
                return { ...contextProduct, quantity: quantity }
            }

            return contextProduct
          }
        )

      const reducedProducts = updatedProducts.map((product) => ({
        id: product.id,
        sku: product.sku,
        quantity: product.quantity,
        bundleId: product.bundleId,
        bundleName: product.bundleName,
        isBundleParent: product.isBundleParent,
      }))

      // NOTE: typescript doesnt restrict the extraProperties of updatedProducts, so explicitedly reducing it to reducedProducts
      // https://github.com/microsoft/TypeScript/issues/12936

      storeCartProducts(reducedProducts)
      cartContext.setProducts([...updatedProducts])
    },
    [cartContext]
  )

  return updateQuantity
}

export const useClearUnavailableProductsEffect = (): boolean => {
  const [completed, setCompleted] = useState(false)
  const removeProduct = useRemoveProduct()
  const cartContext = useContext(CartContext)
  const notificationContext = useContext(NotificationContext)

  const checkAndHandleUnavailableProducts = useCallback(async () => {
    const shopProducts = await shop.products.list()

    const removedProducts = []
    for (const cartProduct of cartContext.products) {
      const shopProduct = shopProducts.find(
        (shopProduct) =>
          shopProduct.sku === cartProduct.sku &&
          // TODO: remove this when product type definition has been updated in shop-sdk
          // @ts-ignore
          shopProduct.storefronts.includes(DEFAULT_STOREFRONT),
        []
      )
      if (shopProduct?.reason_not_available) {
        removedProducts.push({
          name: cartProduct.name,
          reason:
            REASON_NOT_AVAILABLE_MAPPING[shopProduct.reason_not_available],
        })
        removeProduct(cartProduct.sku)
      }
    }

    if (removedProducts.length) {
      notificationContext.setNotification({
        type: "warning",
        message: `${removedProducts.reduce(
          (previousValue, currentItem, currentIndex) =>
            `${previousValue}${currentIndex !== 0 ? " | " : ""}${
              currentItem.name
            } (${currentItem.reason})`,
          ""
        )}`,
        title: "Product not available",
      })
    }
  }, [cartContext.products, notificationContext, removeProduct])

  useEffect(() => {
    if (cartContext.initialised && !completed) {
      checkAndHandleUnavailableProducts()

      setCompleted(true)
    }
  }, [
    cartContext.initialised,
    checkAndHandleUnavailableProducts,
    completed,
    removeProduct,
  ])

  return completed
}
