import { SbGatsbyStory, useStoryblokState } from "gatsby-source-storyblok"
import React from "react"

import FooterElement from "./FooterElement"

type Props = {
  footer: Storyblok.Page & SbGatsbyStory
  socialMedia: Storyblok.Page & SbGatsbyStory
}

const Footer = ({ footer, socialMedia }: Props) => {
  const footerData = useStoryblokState(footer)
  const socialMediaData = useStoryblokState(socialMedia)

  return (
    <FooterElement
      footerData={footerData.content as Storyblok.FooterData}
      socialMedia={socialMediaData.content as Storyblok.SocialMediaList}
    />
  )
}

export default Footer
