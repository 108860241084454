import { useLocation } from "@reach/router"
import classNames from "classnames"
import { Link } from "gatsby"
import { isNumber } from "lodash"
import React, { useCallback, useContext, useEffect, useState } from "react"

import { NavigationV2Context } from "./context"
import HamburgerIcon from "./HamburgerIcon"
import type { SubMenuBlockProps } from "./SubMenuBlock"
import SubMenus from "./SubMenus"
import SupportIcon from "./Supporticon"
import { useGetCartQuantity } from "../../../cart/hooks"
import CartWidget from "../../../cart/V2/CartWidget"
import { Color } from "../../../constants/V2/color"
import PillButton from "../../elements/V2/Buttons/PillButton"
import Grid from "../../elements/V2/Grid"
import Logo from "../../elements/V2/Logo"
import Section from "../../elements/V2/Section"

import { useIsBreakpoint } from "@utils/V2/screen"

export interface MainLink {
  label: string
  link?: string
  subMenuBlocks?: SubMenuBlockProps[]
}

type Props = {
  mode?: "light" | "dark"
  mainLinks: MainLink[]
  primaryButtonText?: string
  primaryButtonLink?: string
  secondaryButtonText: string
  secondaryButtonLink: string
  mobilePrimaryButtonText?: string
  mobilePrimaryButtonLink?: string
  isBannerContents?: boolean
  /**
   * Whether to show the cart widget
   *  @default true
   */
  showCartWidget?: boolean
} & Partial<Storyblok.CTATrackingEvent>

const MAIN_LINK_CLASSES =
  "flex lg-v2:inline-flex justify-between items-center align-middle mr-8-v2 lg-v2:px-[10px] py-24-v2 lg-v2:pt-[11px] lg-v2:pb-[9px] cursor-pointer bg-transparent lg-v2:rounded-8-v2 lg-v2:hover:bg-white-v2 lg-v2:focus-visible:bg-white-v2 lg-v2:hover:text-black-v2 lg-v2:focus-visible:text-black-v2 lg-v2:border border-transparent lg-v2:hover:border-charcoal-v2/10 lg-v2:focus-visible:border-charcoal-v2/10 transition-all duration-200 ease-in-out"
const BORDER_UNDERLINE_CLASSES =
  "border-b border-b-black-v2 border-opacity-10 lg-v2:border-b-transparent"

const Navigation = ({
  mode = "light",
  mainLinks = [],
  primaryButtonText,
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  mobilePrimaryButtonText,
  mobilePrimaryButtonLink,
  isBannerContents = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  showCartWidget = true,
}: Props) => {
  const navigationContext = useContext(NavigationV2Context)
  const numberOfItemsInCart = useGetCartQuantity()
  const [activeSubMenu, setActiveSubMenu] = useState<number | undefined>()
  const [scrolled, setScrolled] = useState(false)
  const { pathname } = useLocation()
  const isDesktop = useIsBreakpoint("desktop")

  const mobileButtonText = mobilePrimaryButtonText || primaryButtonText
  const mobileButtonLink = mobilePrimaryButtonLink || primaryButtonLink
  const displayMobileButton = mobileButtonText && mobileButtonLink

  useEffect(() => {
    if (navigationContext.isMobileMenuOpen) {
      navigationContext.setIsMobileMenuOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleMainLinkHover = useCallback(
    (menuId: number) => {
      if (isNumber(menuId) && isDesktop) {
        setActiveSubMenu(menuId)
      }
    },
    [setActiveSubMenu, isDesktop]
  )

  const handleMainLinkClick = useCallback(
    (menuId: number) => {
      if (isNumber(menuId)) {
        setActiveSubMenu((activeSubMenu) =>
          activeSubMenu === menuId ? undefined : menuId
        )
      }
    },
    [setActiveSubMenu]
  )

  const handleMobileMenuToggle = useCallback((isOpen: boolean) => {
    navigationContext.setIsMobileMenuOpen(isOpen)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      const navElement = document.getElementById("navigation")
      if (navElement) {
        const navTopPosition = navElement.getBoundingClientRect().top
        if (isBannerContents) {
          if (navTopPosition < 0) {
            setScrolled(true)
          } else {
            setScrolled(false)
          }
        } else {
          if (offset > 1) {
            setScrolled(true)
          } else {
            setScrolled(false)
          }
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const isMenuActive = activeSubMenu !== undefined
  const isMenuActiveOnDesktop = isMenuActive && isDesktop
  const isMenuOpenOnMobile = navigationContext.isMobileMenuOpen && !isDesktop

  const color =
    mode === "light" || scrolled || isMenuActiveOnDesktop || isMenuOpenOnMobile
      ? Color.Black
      : Color.White
  return (
    <Section
      className={classNames(
        "pt-md-v2 pb-sm-v2 left-0 z-30 flex max-h-screen w-screen transition-colors duration-200 ease-in-out",
        {
          "bg-white-v2":
            scrolled || isMenuActiveOnDesktop || isMenuOpenOnMobile,
        },
        {
          "fixed top-0": scrolled,
        },
        {
          "shadow-[0_16px_16px_-8px] shadow-charcoal-v2/20 lg-v2:shadow-none":
            navigationContext.isMobileMenuOpen,
        },
        {
          absolute: !scrolled,
        }
      )}
      onMouseLeave={() => setActiveSubMenu(undefined)}
    >
      <div className="flex max-h-full flex-1 flex-col gap-x-32-v2 lg-v2:flex-row">
        <div className="mt-0 flex items-center justify-between sm-v2:-mt-1">
          <Link
            to={`${process.env.GATSBY_PREFIX_PATH || ""}/`}
            aria-label="Yoco Logo"
          >
            <Logo
              className={classNames(
                mode === "light" ||
                  scrolled ||
                  isMenuActiveOnDesktop ||
                  isMenuOpenOnMobile
                  ? "fill-black-v2"
                  : "fill-white-v2"
              )}
            />
          </Link>

          <div className="flex items-center gap-x-24-v2 md-v2:gap-x-40-v2 lg-v2:hidden">
            {numberOfItemsInCart > 0 && showCartWidget ? (
              <CartWidget numberOfItems={numberOfItemsInCart} />
            ) : null}

            <HamburgerIcon
              color={color}
              mobileMenuOpen={navigationContext.isMobileMenuOpen}
              onToggle={handleMobileMenuToggle}
            />
          </div>
        </div>

        <div
          className={classNames(
            "pointer-events-none -mx-gutter-xs-v2 mt-8-v2 flex max-h-0 flex-1 flex-col justify-between overflow-y-hidden rounded bg-white-v2 text-body-md-v2 font-medium transition-all duration-200 ease-in-out sm-v2:-mx-gutter-sm-v2 md-v2:-mx-gutter-md-v2 lg-v2:pointer-events-auto lg-v2:-mx-24-v2 lg-v2:mt-0 lg-v2:max-h-screen lg-v2:flex-row lg-v2:items-center lg-v2:overflow-y-visible lg-v2:bg-transparent lg-v2:px-24-v2",
            isMenuOpenOnMobile
              ? "pointer-events-auto max-h-screen translate-x-0"
              : "translate-x-full lg-v2:[transform:unset]"
          )}
        >
          <div className="max-h-screen flex-auto overflow-y-auto transition-all duration-200 ease-in-out">
            {mainLinks.map((mainLink, index) => (
              <React.Fragment key={`${mainLink.label}-${index}`}>
                <Link
                  className={classNames(
                    MAIN_LINK_CLASSES,
                    {
                      "px-gutter-xs-v2 sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2":
                        navigationContext.isMobileMenuOpen,
                    },
                    {
                      "text-black-v2 lg-v2:text-white-v2":
                        mode === "dark" && !scrolled && !isMenuActive,
                    },
                    {
                      "text-black-v2":
                        (mode === "light" && scrolled) || isMenuActive,
                    }
                  )}
                  key={`navigation-main-link-${index}`}
                  to={mainLink.link || ""}
                  onClick={() => handleMainLinkClick(index)}
                  onMouseEnter={() => handleMainLinkHover(index)}
                  onFocus={() => handleMainLinkHover(index)}
                  data-menu-id={index}
                >
                  {mainLink.label}

                  <img
                    src="/icons/V2/chevron-down.svg"
                    alt={`Open ${mainLink.label}`}
                    width={12}
                    height={9}
                    className={classNames(
                      "transform transition-transform duration-200 ease-in-out lg-v2:hidden",
                      {
                        "rotate-180": activeSubMenu === index,
                      },
                      { hidden: !mainLink.subMenuBlocks?.length }
                    )}
                  />
                </Link>
                {isMenuOpenOnMobile && (
                  <div
                    className={classNames(
                      BORDER_UNDERLINE_CLASSES,
                      {
                        "transition-all duration-300 ease-in-out":
                          navigationContext.isMobileMenuOpen,
                      },
                      {
                        "mx-gutter-xs-v2 sm-v2:mx-gutter-sm-v2 md-v2:mx-gutter-md-v2":
                          activeSubMenu === index &&
                          navigationContext.isMobileMenuOpen,
                      }
                    )}
                  />
                )}

                {mainLink.subMenuBlocks?.length ? (
                  <div
                    className={classNames(
                      "left-0 z-50 max-h-0 w-full overflow-hidden transition-all duration-200 ease-in-out lg-v2:pointer-events-none lg-v2:absolute lg-v2:mt-[22px] lg-v2:max-h-max lg-v2:px-gutter-lg-v2 lg-v2:opacity-0 xl-v2:px-gutter-xl-v2",
                      {
                        "max-h-[800px] pb-24-v2 lg-v2:pointer-events-auto lg-v2:opacity-100":
                          activeSubMenu === index,
                      },
                      {
                        "bg-white-v2 lg-v2:shadow-[0_16px_16px_-8px] lg-v2:shadow-charcoal-v2/20":
                          scrolled || isMenuActive,
                      }
                    )}
                  >
                    <div
                      className={
                        "rounded-sm-v2 bg-white-v2 px-gutter-xs-v2 py-20-v2 transition-height sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2 md:py-24-v2 lg-v2:-ml-32-v2 lg-v2:w-[calc(100%_+_64px)] lg-v2:bg-transparent lg-v2:px-32-v2 lg-v2:py-64-v2"
                      }
                    >
                      <Grid
                        className={classNames(
                          "transition-all duration-300",
                          activeSubMenu === index
                            ? "translate-y-0 opacity-100"
                            : "translate-y-1/3 opacity-0 lg:translate-y-1/2"
                        )}
                      >
                        <SubMenus subMenus={mainLink.subMenuBlocks} />
                      </Grid>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </div>

          <div className="relative flex items-center justify-between gap-x-8-v2 py-[11px] lg-v2:py-0">
            {/* Desktop Right Items */}
            <div className="hidden items-center lg-v2:flex">
              <Link
                to="https://support.yoco.help/en/"
                className={classNames(
                  MAIN_LINK_CLASSES,
                  "group gap-x-10-v2 border-b-transparent transition-opacity duration-200 ease-in-out",
                  {
                    "text-black-v2 lg-v2:text-white-v2":
                      mode === "dark" && !scrolled && !isMenuActive,
                  },
                  {
                    "text-black-v2":
                      (mode === "light" && scrolled) || isMenuActive,
                  }
                )}
              >
                <SupportIcon
                  className="transition-all duration-200 ease-in-out"
                  svgClassName={classNames(
                    "group-hover:stroke-black-v2 group-focus-visible:stroke-black-v2",
                    scrolled || isMenuActive || mode === "light"
                      ? "stroke-black-v2 "
                      : "stroke-white-v2 "
                  )}
                />
                Support
              </Link>
              <Link
                className={classNames(
                  MAIN_LINK_CLASSES,
                  "border-b-transparent transition-opacity duration-200 ease-in-out",
                  {
                    "text-black-v2 lg-v2:text-white-v2":
                      mode === "dark" && !scrolled && !isMenuActive,
                  },
                  {
                    "text-black-v2":
                      (mode === "light" && scrolled) || isMenuActive,
                  }
                )}
                to={secondaryButtonLink}
              >
                {secondaryButtonText}
              </Link>
              <div className="hidden transition-opacity duration-200 ease-in-out lg-v2:block">
                {numberOfItemsInCart > 0 && showCartWidget ? (
                  <CartWidget numberOfItems={numberOfItemsInCart} />
                ) : (
                  !!(primaryButtonText && primaryButtonLink) && (
                    <PillButton
                      size="medium"
                      hideIcon
                      color={Color.Blue}
                      overrideTextColor={color}
                      style="bordered"
                      text={primaryButtonText}
                      linkUrl={primaryButtonLink}
                      className="transition-all"
                      trackingEvent={trackingEvent}
                      trackingEventKey={trackingEventKey}
                      trackingEventValue={trackingEventValue}
                    />
                  )
                )}
              </div>
            </div>

            {/* Tablet Menu Footer */}
            <div className="hidden w-full items-center justify-between px-grid-sm-v2 sm-v2:flex lg-v2:hidden">
              {displayMobileButton && (
                <div className="flex items-center">
                  <PillButton
                    size="large"
                    color={Color.Blue}
                    style="solid"
                    text={mobileButtonText}
                    linkUrl={mobileButtonLink}
                    className={classNames(
                      "transition-opacity duration-200 ease-in-out"
                    )}
                  />
                </div>
              )}

              <div className="flex items-center gap-32-v2">
                <Link
                  to="https://support.yoco.help/en/"
                  className={classNames(
                    MAIN_LINK_CLASSES,
                    "gap-x-10-v2 border-b-transparent transition-opacity duration-200 ease-in-out",
                    {
                      "text-black-v2 lg-v2:text-white-v2":
                        mode === "dark" && !scrolled,
                    },
                    {
                      "text-black-v2":
                        mode === "light" && (scrolled || isMenuActive),
                    }
                  )}
                >
                  <SupportIcon
                    className="transition-all duration-200 ease-in-out"
                    svgClassName="stroke-black-v2"
                  />
                  Support
                </Link>
                <Link
                  className={classNames(
                    MAIN_LINK_CLASSES,
                    "border-b-transparent transition-opacity duration-200 ease-in-out",
                    {
                      "text-black-v2 lg-v2:text-white-v2":
                        mode === "dark" && !scrolled,
                    },
                    {
                      "text-black-v2":
                        mode === "light" && (scrolled || isMenuActive),
                    }
                  )}
                  to={secondaryButtonLink}
                >
                  {secondaryButtonText}
                </Link>
              </div>
            </div>

            {/* Mobile Menu Footer */}
            <div className="flex w-full flex-col items-stretch px-gutter-xs-v2 sm-v2:hidden">
              <div className="flex w-full items-center justify-between">
                <Link
                  to="https://support.yoco.help/en/"
                  className={classNames(
                    MAIN_LINK_CLASSES,
                    "gap-x-10-v2 border-b-transparent transition-opacity duration-200 ease-in-out",
                    {
                      "text-black-v2 lg-v2:text-white-v2":
                        mode === "dark" && !scrolled,
                    },
                    { "text-black-v2": mode === "light" && scrolled }
                  )}
                >
                  <SupportIcon
                    className="transition-all duration-200 ease-in-out"
                    svgClassName="stroke-black-v2"
                  />
                  Support
                </Link>
                <Link
                  className={classNames(
                    MAIN_LINK_CLASSES,
                    "border-b-transparent transition-opacity duration-200 ease-in-out",
                    {
                      "text-black-v2 lg-v2:text-white-v2":
                        mode === "dark" && !scrolled,
                    },
                    { "text-black-v2": mode === "light" && scrolled }
                  )}
                  to={secondaryButtonLink}
                >
                  {secondaryButtonText}
                </Link>
              </div>
              {displayMobileButton && (
                <div className="mt-4-v2 w-full">
                  <PillButton
                    size="medium"
                    color={Color.Blue}
                    style="solid"
                    text={mobileButtonText}
                    linkUrl={mobileButtonLink}
                    className={classNames(
                      "transition-opacity duration-200 ease-in-out"
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Navigation
