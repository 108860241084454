import { useLocation } from "@reach/router"
import classNames from "classnames"
import { storyblokEditable } from "gatsby-source-storyblok"
import React, { useCallback, useEffect, useRef } from "react"

import { Color } from "../../constants/V2/color"
import Link from "../elements/Link"
import Logo from "../elements/V2/Logo"
import Typography from "../elements/V2/Typography"

import { linkIsNotNull } from "@utils/storyblok"

type Props = {
  footerData: Storyblok.FooterData
  socialMedia: Storyblok.SocialMediaList
}

const FooterElement = ({ footerData, socialMedia }: Props) => {
  const location = useLocation()
  const footerElement = useRef<HTMLElement>(null)

  const renderTextOrLink = useCallback(
    (item: Storyblok.FooterItem, index: number) => {
      let className = "block mb-12-v2 overflow-hidden text-ellipsis "
      const key = `footer-column-item-${index}`

      if (item.textLight) {
        className += "opacity-70 "
      }
      if (item.link && linkIsNotNull(item.link)) {
        className +=
          "cursor-pointer hover:opacity-60 focus-visible:opacity-60 transition-opacity ease-in-out duration-300 "
      }

      if (item.link && linkIsNotNull(item.link)) {
        return (
          <Link
            key={key}
            link={item.link}
            opensNewTab={item.isExternal}
            className={className}
            // @ts-ignore to have more strict typing compared to Storybloks generic object typing
            {...storyblokEditable(item)}
          >
            <Typography
              size="body-md"
              weight={item.textLight ? "book" : "medium"}
              font="grotesk"
              color={Color.White}
              text={item.text}
            />
          </Link>
        )
      }

      if (item.link && linkIsNotNull(item.link)) {
        return (
          <Typography
            key={key}
            size="body-md"
            weight={item.textLight ? "book" : "medium"}
            font="grotesk"
            color={Color.White}
            className={className}
            text={item.text}
            // @ts-ignore to have more strict typing compared to Storybloks generic object typing
            {...storyblokEditable(item)}
          />
        )
      }

      return (
        <p
          key={key}
          className={className}
          // @ts-ignore to have more strict typing compared to Storybloks generic object typing
          {...storyblokEditable(item)}
        >
          {item.text}
        </p>
      )
    },
    []
  )

  const renderColumn = useCallback(
    (column: Storyblok.FooterColumn, index: number) => {
      let className = "md-v2:col-auto "
      let headingClassName =
        "opacity-70 mb-16-v2 md-v2:mb-32-v2 uppercase tracking-wide"

      if (column.addTopPadding) {
        className += "sm-v2:mt-32-v2 "
        if (!column.heading) {
          className += "md-v2:pt-[17.492px] "
        }
      }
      if (index < footerData.columns.length / 2) {
        className += "col-start-1 "
      } else {
        className += "sm-v2:self-auto col-start-2 "
        if (index === footerData.columns.length - 1) {
          className += "self-end"
        } else {
          className += "self-center"
        }
      }

      if (column.hideHeadingOnMobile) {
        headingClassName += " hidden md-v2:block"
      }

      return (
        <div key={`footer-column-${index}`} className={className}>
          {column.heading ? (
            <Typography
              text={column.heading}
              className={headingClassName}
              color={Color.White}
              weight="book"
              font="grotesk"
              size="subscript-md"
            />
          ) : null}
          {column.items.map(renderTextOrLink)}
        </div>
      )
    },
    [renderTextOrLink, footerData.columns]
  )

  const renderSocialMediaIcon = useCallback(
    (item: Storyblok.SocialMedia, index: number) => {
      return (
        <a
          key={`social-icon-${index}`}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="transition-opacity duration-300 ease-in-out hover:opacity-60 focus-visible:opacity-60"
        >
          <img
            src={item.icon}
            alt={item.alt}
            width={35}
            height={35}
            loading="lazy"
          />
        </a>
      )
    },
    []
  )

  useEffect(() => {
    const excludeFooter = footerData.excludedPages?.items?.some((path) =>
      location?.pathname.includes(path)
    )
    if (footerElement?.current) {
      if (excludeFooter) {
        footerElement.current.classList.add("hidden")
      } else {
        footerElement.current.classList.remove("hidden")
      }
    }
  }, [footerData.excludedPages?.items, location?.pathname])

  return (
    <footer
      ref={footerElement}
      className={classNames(
        // TODO: Refactor gutter classes to a constant
        "px-gutter-xs-v2 sm-v2:px-gutter-sm-v2 md-v2:px-gutter-md-v2 lg-v2:px-gutter-lg-v2 xl-v2:px-gutter-xl-v2",
        "rounded-t-lg-v2 relative z-10 bg-black-v2 py-48-v2 text-white lg-v2:py-64-v2"
      )}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(footerData)}
    >
      <div className="mb-3xl-v2 grid grid-flow-row-dense grid-cols-2 justify-between gap-y-16-v2 md-v2:grid-cols-3 md-v2:items-start md-v2:gap-y-32-v2 lg-v2:grid-cols-6">
        {footerData?.columns ? footerData.columns.map(renderColumn) : null}
      </div>
      <div className="flex flex-col justify-between gap-y-32-v2 pt-40-v2 md-v2:flex-row lg-v2:items-center">
        <div className="flex items-center gap-x-24-v2">
          <Link
            link={`${process.env.GATSBY_PREFIX_PATH || ""}/`}
            className="cursor-pointer"
            aria-label="Yoco Logo"
          >
            <Logo
              alt="Yoco Card Machines and Online Payments"
              className="h-[30px] w-[79px] fill-white-v2 md-v2:h-[36px] md-v2:w-[94px] lg-v2:h-[42px] lg-v2:w-[110px]"
              data-test-id="global-footer-image"
            />
          </Link>
          <p
            className="text-body-sm-v2 leading-none"
            data-test-id="global-footer-copyright"
          >
            {new Date().getFullYear()}©
          </p>
        </div>
        <div className="flex flex-wrap items-center gap-x-20-v2 sm-v2:flex-nowrap">
          {socialMedia?.socialMediaList
            ? socialMedia.socialMediaList.map(renderSocialMediaIcon)
            : null}
        </div>

        <div>
          <p
            className="self-start text-fs-13 font-medium"
            data-test-id="global-footer-region"
          >
            South Africa - <span className="opacity-70">Eng</span>
            <img
              src={"/icons/globe.svg"}
              alt="Yoco - Country / Language"
              width={32}
              height={32}
              className="mb-1 ml-2.5 inline"
              loading="lazy"
            />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default FooterElement
